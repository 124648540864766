import imageCompression from 'browser-image-compression';
import { Bot } from 'ignite360-core';
import { PLATFORM_LOGOS } from '@/constants';

export function startDownload(blob: Blob, downloadName = 'en-US') {
  const url = URL.createObjectURL(blob);
  const anchor: HTMLAnchorElement = document.createElement('a');
  anchor.href = url;
  anchor.download = downloadName;
  anchor.style.position = 'fixed';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export function copyToClipboard(value: string) {
  const textArea: HTMLTextAreaElement = document.createElement('textarea');
  textArea.value = value;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);

  const selection = document.getSelection();
  let selectionBackup;
  if (selection && selection.rangeCount > 0) {
    selectionBackup = selection.getRangeAt(0);
  }

  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);

  if (selection && selectionBackup) {
    selection.removeAllRanges();
    selection.addRange(selectionBackup);
  }
}

export const delay = (amountInMs: number) =>
  new Promise((resolve) => setTimeout(resolve, amountInMs));

export async function getCompressedImage(
  imageFile: File,
  options: Parameters<typeof imageCompression>[1] = {
    useWebWorker: true,
    maxSizeMB: 1,
    fileType: imageFile.type,
  },
): Promise<File> {
  const compressedImage = await imageCompression(imageFile, options);
  return new File([compressedImage], imageFile.name, {
    type: imageFile.type,
  });
}

export function isPlatformConnected(bot: Bot, p: string) {
  const platform = p === 'facebook' ? 'facebookMessenger' : p;
  if (platform === 'facebookMessenger' && bot.platformData[platform]) {
    return (
      bot.platformData[platform].enablePosting &&
      !!(bot.platformData[platform].pageId && bot.platformData[platform].pageAccessToken)
    );
  } else if (platform === 'instagram' && bot.platformData[platform]) {
    return (
      bot.platformData[platform].enablePosting &&
      !!(bot.platformData[platform].pageId && bot.platformData[platform].pageAccessToken)
    );
  } else if (platform === 'linkedIn' && bot.platformData[platform]) {
    return (
      bot.platformData[platform].enablePosting &&
      !!(bot.platformData[platform]._id && bot.platformData[platform].type)
    );
  } else if (platform === 'googleBusiness' && bot.platformData[platform]) {
    return (
      bot.platformData[platform].enablePosting &&
      !!(
        bot.platformData[platform].locationId &&
        bot.platformData[platform].accountId &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        bot.platformData[platform].authToken
      ) &&
      !bot.platformData[platform].isExpired
    );
  }
  return false;
}
export const getPlatformLogo = (platform: string): string => {
  return PLATFORM_LOGOS[platform] ? PLATFORM_LOGOS[platform] : PLATFORM_LOGOS['FALLBACK'];
};
